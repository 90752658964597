<template>
  <v-container fluid>
    <div class="main">
      <v-row>
        <v-col cols="12">
          <v-card class="rounded-lg shadow">
            <v-card-title>
              <div>
                <v-text-field
                    v-model="filter.keyword"
                    append-icon="mdi-magnify"
                    clearable
                    dense
                    filled
                    hide-details
                    placeholder="Rechercher..."
                    rounded
                    single-line
                />
              </div>

              <v-spacer/>

              <div>
                <v-btn class="rounded-lg"
                       color="grey"
                       text
                       @click="fetchData()">
                  <v-icon left>mdi-reload</v-icon>
                  Mettre à jour
                </v-btn>
                <v-btn text
                       color="grey"
                       :loading="exportLoading"
                       class="rounded-lg"
                       @click="exportExcel()">
                  <v-icon left>mdi-download</v-icon>
                  Exporter
                </v-btn>
                <v-btn text
                       color="grey"
                       class="rounded-lg"
                       @click="dialog = true">
                  <v-icon left>mdi-filter-outline</v-icon>
                  Filtrer
                </v-btn>
              </div>

            </v-card-title>

            <v-divider/>

            <v-card-text class="pa-0">
              <div v-if="isLoading">
                <v-skeleton-loader type="table"></v-skeleton-loader>
              </div>

              <div v-else>
                <div v-if="!isLoading && transactions.data.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Nom Destinataire</th>
                        <th>Téléphone Destinataire</th>
                        <th>Date</th>
                        <th>Montant</th>
                        <th>Statut</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, i) in transactions.data" :key="i">

                        <td class="text-no-wrap font-weight-medium">
                          {{ item.recipient.name }}
                        </td>

                        <td class="text-no-wrap">
                            {{ item.recipient.phone }}
                        </td>

                        <td class="text-no-wrap">
                          {{ dateFormat(item.created_at) }}
                        </td>

                        <td class="text-no-wrap">
                            <span class="gifty--text font-weight-medium">
                              {{ CurrencyFormatting(item.amount) }} DZD
                            </span>
                        </td>

                        <td>
                          <v-chip :color="setStatusColor(item.state)"
                                  dark
                                  small>
                            {{ setStatus(item.state) }}
                          </v-chip>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-divider/>

                  <div class="d-flex justify-space-between pl-4 pr-4 pt-6">
                    <div :style="{ width: '120px' }">
                      <v-select v-model="filter.size"
                                :items="[10, 20, 30, 50,100]"
                                dense
                                label="Ligne par page"
                                outlined
                                @change="onPageChange"
                      ></v-select>
                    </div>

                    <v-spacer/>

                    <v-pagination
                        v-if="transactions.total > 15"
                        v-model="filter.page"
                        :length="Math.ceil(transactions.total / filter.size)"
                        circle
                        total-visible="6"
                        @input="onPageChange"
                    ></v-pagination>
                  </div>
                </div>

                <div v-else class="text-center">
                  <NoResults @reload="fetchData"/>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="dialog" width="600">
      <v-card>

        <v-card-title>
          Filtrer par
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-8">
          <v-autocomplete v-model="filter.recipients"
                          :item-text="'recipient.name'"
                          :item-value="'recipient_account_id'"
                          :items="recipients"
                          :loading="recipientsLoading"
                          chips
                          class="mb-3"
                          clearable
                          deletable-chips
                          dense
                          hide-details
                          label="Destinataires..."
                          multiple
                          outlined
                          prepend-inner-icon="mdi-account"
                          small-chips
          ></v-autocomplete>

          <v-dialog
              ref="dialogStartDate"
              v-model="modalStartDate"
              persistent
              width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="formFilter.startDate"
                  class="mb-3"
                  clearable
                  dense
                  hide-details
                  label="Date début"
                  outlined
                  prepend-inner-icon="mdi-sort-calendar-descending"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="formFilter.startDate"
                @input="
                        [(formFilter.endDate = null), (modalStartDate = false)]
                      "
            >
            </v-date-picker>
          </v-dialog>

          <v-dialog
              ref="dialogEndDate"
              v-model="modalEndDate"
              persistent
              width="290px"
              @click="modalEndDate = false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="formFilter.endDate"
                  clearable
                  dense
                  hide-details
                  label="Date fin"
                  outlined
                  prepend-inner-icon="mdi-sort-calendar-ascending"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="formFilter.endDate"
                :min="formFilter.startDate"
                @input="modalEndDate = false"
            >
            </v-date-picker>
          </v-dialog>

          <v-radio-group
              v-model="formFilter.status"
              hide-details
              label="Statut"
          >
            <v-radio label="Tous" value="all"></v-radio>
            <v-radio label="En attente" value="warning"></v-radio>
            <v-radio label="Acceptée" value="accepted"></v-radio>
            <v-radio label="Réfusée" value="refused"></v-radio>
          </v-radio-group>

          <div class="bg-light rounded-lg mt-5" v-if="!rangeLoading">
            <v-range-slider
                v-model="filter.range"
                :max="max"
                :min="0"
                class="px-10 pt-10 pb-5"
                thumb-size="15"
                hide-details
                track-color="gifty lighten-4"
                dark
                color="gifty"
                thumb-label="always"
            >
              <template v-slot:thumb-label="item">
                <v-btn dark
                       class="gifty"
                       depressed
                       small
                >
                  {{ CurrencyFormatting(item.value) }} DZ
                </v-btn>
              </template>

            </v-range-slider>
          </div>

          <v-progress-circular v-else
                               class="mt-4"
                               color="gifty"
                               indeterminate
                               style="margin: auto;"
          ></v-progress-circular>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="gifty" dark depressed
                 @click="[filter.page = 1,fetchData()]">
            <v-icon left>mdi-magnify</v-icon>
            Recherche
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import moment from "moment";

export default {
  components: {NoResults},
  data() {
    return {
      dialog: false,
      exportLoading: false,
      rangeLoading: true,
      recipientsLoading: false,

      recipients: [],

      set_paginate: 10,

      dialogSms: false,
      uploadDialog: false,
      updateNameDialog: false,
      btnUpdateLoading: false,
      uploadErrors: [],
      pagination: {
        current: 1,
        total: 0,
      },
      uploadForm: {
        excel_file: "",
        amount: "",
      },

      updateNameForm: {
        id: 0,
        amout: 0,
      },

      formFilter: {
        status: "all",
      },
      updateNameErrors: {},
      btnUploadLoading: false,
      ischeckLoading: false,
      modalStartDate: false,
      modalEndDate: false,
      max: 0,
      filter: {
        salepoint_type_ids: [],

        selectedCommunes: [],
        selectedWilayas: [],
        range: [0, 10000],
        has_gifty: "all",
        period: [],
        startDate: "",
        endDate: "",
        keyword: "",
        size: 10,
        page: 1,
        recipients: [],
      },
    };
  },
  methods: {
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY, HH:mm');
    },
    fetchData() {
      this.exportLoading = false;
      this.filter.keyword = this.keyword;
      this.filter.startDate = this.formFilter.startDate;
      this.filter.endDate = this.formFilter.endDate;
      this.filter.status = this.formFilter.status;
      this.$store.dispatch("fetchTransactions", {
        filter: this.filter,
      });
    },
    setStatusColor(status) {
      switch (status) {
        case "pending":
          return "warning ";
        case "verification":
          return "warning ";
        case "refused":
          return "error";
        case "accepted":
          return "success ";
      }
    },
    setStatus(status) {
      switch (status) {
        case "pending":
          return "En attente";
        case "verification":
          return "En verification";
        case "refused":
          return "Refusée";
        case "accepted":
          return "Acceptée";
      }
    },

    onPageChange() {
      this.fetchData();
    },

    filterRegion() {
      var numeric_array = [];
      for (var item in this.filter.selectedWilayas) {
        numeric_array.push(this.filter.selectedWilayas[item]);
      }
      console.log(numeric_array);
      this.$store.dispatch("fetchCommunes", {
        filter: numeric_array,
      });
    },
    resetFilter() {
      this.keyword = "";

      this.filter = {
        selectedCommunes: [],
        selectedWilayas: [],
        has_gifty: "all",
        period: [],
        startDate: "",
        endDate: "",
        keyword: "",
        size: 10,
      };
    },

    getRange() {
      this.rangeLoading = true;
      HTTP.get("refill/range")
          .then((res) => {
            this.max = res.data.max;
            this.filter.range = [res.data.min, res.data.max];
            this.rangeLoading = false;
          })
          .catch((err) => {
            console.log(err);
            this.rangeLoading = false;
          });
    },
    getRecepients() {
      this.recipientsLoading = true;
      HTTP.get("/refill/recipients")
          .then((res) => {
            this.recipients = res.data;
            this.recipientsLoading = false;
          })
          .catch((err) => {
            console.log(err);
            this.recipientsLoading = false;
          });
    },
    exportExcel() {

      let baseUrl = process.env.VUE_APP_FILE_URL;
      let token = this.$store.state.user.access_token;
      let userId = this.$store.state.user.user.id;

      let url =
          baseUrl +
          "/api/refill/sent/export?token=" +
          token +
          "&user_id=" +
          userId;

      url += "&filter=" + encodeURIComponent(JSON.stringify(this.filter));

      // window.location.href = url;
      this.downloadFile(url)
    },

    downloadFile(urlToSend) {
      this.exportLoading = true;
      var req = new XMLHttpRequest();
      req.open("GET", urlToSend, true);
      req.responseType = "blob";
      req.onload = (event) => {
        var blob = req.response;
        var fileName = "Transactions"; //if you have the fileName header available
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        this.exportLoading = false;
      };

      req.send();
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.getLoading;
    },

    salepoints() {
      return this.$store.getters.getSalepoints;
    },
    wilayas() {
      return this.$store.getters.getWilayas;
    },

    communes() {
      return this.$store.getters.getCommunes;
    },

    database() {
      return this.$store.getters.getDatabase;
    },

    transactions() {
      return this.$store.getters.getTransactions;
    },

    user() {
      return this.$store.getters.getUser;
    },
    messages() {
      return 0;
    },
    keyword() {
      return this.filter.keyword;
    },
  },
  watch: {
    keyword() {
      this.fetchData();
    },
  },

  mounted() {
    this.getRange();
    this.fetchData();
    this.getRecepients();
    if (this.wilayas.length === 0) this.$store.dispatch("fetchwilayas");
  },
};
</script>